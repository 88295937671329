// Generated by Framer (e2fa450)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {o3mCx8mRp: {hover: true}, VwWqix0FR: {hover: true}};

const cycleOrder = ["VwWqix0FR", "o3mCx8mRp"];

const serializationHash = "framer-ExoP4"

const variantClassNames = {o3mCx8mRp: "framer-v-4u8pt6", VwWqix0FR: "framer-v-1yyyyn3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "VwWqix0FR", "Variant 2": "o3mCx8mRp"}

const getProps = ({click2, height, id, link, rotation, width, ...props}) => { return {...props, ENBi3s1e3: rotation ?? props.ENBi3s1e3 ?? 90, eR3W980IG: link ?? props.eR3W980IG, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "VwWqix0FR", Ygo8G81J9: click2 ?? props.Ygo8G81J9} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;rotation?: number;link?: string;click2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ENBi3s1e3, eR3W980IG, Ygo8G81J9, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "VwWqix0FR", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1bosiwa = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (Ygo8G81J9) {const res = await Ygo8G81J9(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={eR3W980IG} smoothScroll {...addPropertyOverrides({"o3mCx8mRp-hover": {href: undefined}, o3mCx8mRp: {href: undefined}}, baseVariant, gestureVariant)}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1yyyyn3", className, classNames)} framer-tjvjgw`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"VwWqix0FR"} onTap={onTap1bosiwa} ref={ref ?? ref1} style={{rotate: 0, ...style}} variants={{o3mCx8mRp: {rotate: ENBi3s1e3}}} {...addPropertyOverrides({"o3mCx8mRp-hover": {"data-framer-name": undefined}, "VwWqix0FR-hover": {"data-framer-name": undefined}, o3mCx8mRp: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><SVG className={"framer-1rqp0kn"} data-framer-name={"graphic"} fill={"var(--token-6d428932-6822-4df3-b17f-2473523ee242, rgb(186, 185, 182))"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"nPiuEtDpJ"} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"size-6\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m19.5 8.25-7.5 7.5-7.5-7.5\"/></svg>"} withExternalLayout {...addPropertyOverrides({"o3mCx8mRp-hover": {fill: "var(--token-5d79a119-090e-43db-a721-cac1a48d3760, rgb(51, 51, 51))"}, "VwWqix0FR-hover": {fill: "var(--token-5d79a119-090e-43db-a721-cac1a48d3760, rgb(51, 51, 51))"}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ExoP4.framer-tjvjgw, .framer-ExoP4 .framer-tjvjgw { display: block; }", ".framer-ExoP4.framer-1yyyyn3 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 32px; height: 60px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 59px; }", ".framer-ExoP4 .framer-1rqp0kn { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 60px); position: relative; width: 60px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ExoP4.framer-1yyyyn3 { gap: 0px; } .framer-ExoP4.framer-1yyyyn3 > * { margin: 0px; margin-left: calc(32px / 2); margin-right: calc(32px / 2); } .framer-ExoP4.framer-1yyyyn3 > :first-child { margin-left: 0px; } .framer-ExoP4.framer-1yyyyn3 > :last-child { margin-right: 0px; } }", ".framer-ExoP4.framer-v-4u8pt6.framer-1yyyyn3 { height: min-content; width: min-content; }", ".framer-ExoP4.framer-v-4u8pt6 .framer-1rqp0kn { height: var(--framer-aspect-ratio-supported, 32px); width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 59
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"o3mCx8mRp":{"layout":["auto","auto"]},"M3fWH8lJ6":{"layout":["fixed","fixed"]},"g4qyIBODo":{"layout":["auto","auto"]}}}
 * @framerVariables {"ENBi3s1e3":"rotation","eR3W980IG":"link","Ygo8G81J9":"click2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerb7itsFdwl: React.ComponentType<Props> = withCSS(Component, css, "framer-ExoP4") as typeof Component;
export default Framerb7itsFdwl;

Framerb7itsFdwl.displayName = "Arrows";

Framerb7itsFdwl.defaultProps = {height: 60, width: 59};

addPropertyControls(Framerb7itsFdwl, {variant: {options: ["VwWqix0FR", "o3mCx8mRp"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, ENBi3s1e3: {defaultValue: 90, title: "Rotation", type: ControlType.Number, unit: "°"}, eR3W980IG: {title: "Link", type: ControlType.Link}, Ygo8G81J9: {title: "Click 2", type: ControlType.EventHandler}} as any)

addFonts(Framerb7itsFdwl, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})